import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import Image from '~components/Image'
import { Plus } from '../../Svg';

const Swatch = ({ className, image, title, modal, minimal }) => {

  const {mobile} = breakpoints

  const [hovered, setHovered] = useState(false)

  return (
    <div 
      className={className}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div
        css={css`
          border-radius: 100%;
          overflow: hidden;
          -webkit-mask-image: -webkit-radial-gradient(white, black);
          margin-bottom: ${ minimal ? `0` : !modal ? `10px` : `38px`};
          position: relative;
          ${mobile}{
            margin-bottom: ${ minimal ? `0` : !modal ? `10px` : `28px`};
          }
        `}
      >
        <Image
          aspectRatio={1}
          asset={image}
        />
        {!modal && 
          <div css={css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            display: grid;
            align-content: center;
            justify-content: center;
          `}>
            <Plus css={css`
              width: 10px;
              height: 10px;
              color: #fff;
              opacity: ${hovered ? `1` : `0`};
              transition: 0.3s opacity;
              ${mobile}{
                opacity: 1;
              }
            `}/>
          </div>
        }
      </div>
      {!minimal && <h6 css={css`text-align: center;`}>{title}</h6>}
      
    </div>
  )
}

Swatch.propTypes = {
  image: PropTypes.object,
  title: PropTypes.string,
  minimal: PropTypes.bool,
}

Swatch.defaultProps = {
  title: `M60`,
}

export default Swatch
