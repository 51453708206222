import React from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import RichText from '~components/RichText'
import Image from '~components/Image'

const ProductFeature = ({ className, image, title, text }) => {

  const {mobile} = breakpoints

  return (
    <div
      className={className}
      css={css`
        text-align: center;
      `}
    >
      <Image 
      asset={image} 
      aspectRatio={1.5} 
      css={css`
        margin-bottom: 91px;
        ${mobile}{
          margin-bottom: 40px;
        }
      `}/>
      <h4 css={css`
        margin-bottom: 40px;
        ${mobile}{
          margin-bottom: 25px;
        }
      `}>{title}</h4>
      <div css={css`
        max-width: 540px;
        margin: 0 auto;
      `}>
        <RichText content={text}/>
      </div>
    </div>
  )
}

ProductFeature.propTypes = {
  title: PropTypes.string,
  image: PropTypes.object,
  text: PropTypes.array,
}

ProductFeature.defaultProps = {
  title: `Modularity Redefined`,
  text:  [
    {
        "_key": "f9d17a10baa5",
        "_type": "block",
        "children": [
            {
                "_key": "8f25454437bd",
                "_type": "span",
                "marks": [],
                "text": "Each piece we stock is available in a range of finishes and dimensions to suit your specific tastes and needs. Our design qualified team is well placed to assist clients and trade in finding solutions without compromise, to achieve the desired composition, quality and harmony for each individual setting."
            }
        ],
        "markDefs": [],
        "style": "normal"
    }
  ]
}

export default ProductFeature
