import React from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import Image from '~components/Image'
import Button from '~components/blocks/Button'
import { Link } from 'gatsby';

const BrandTile = ({ className, title, shortDesc, brandLink, catalogueLink, image, id }) => {

  const {mobile, tablet} = breakpoints

  return (
    <div
    id={id}
      className={className}
      css={css`
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        column-gap: 20px;
        @media (max-width: 750px) {
          grid-template-columns: repeat(12, 1fr);
          column-gap: 10px;
        }
      `}
    >
      <h4 
        css={css`
          display: none;
          @media (max-width: 750px) {
            display: block;
            margin-bottom: 26px;
          }
        `}>
          Brand
        </h4>
      <div css={css`
        grid-column: 1/4;
        @media (max-width: 750px) {
          grid-row: 3;
          grid-column: span 12;
        }
      `}>
        <h4 css={css`
          margin-bottom: 54px;
          ${tablet} {
            margin-bottom: 32px;
          }
          @media (max-width: 750px) {
            display: none;
          }
        `}>
          Brand
        </h4>
        <div css={css`
          margin-bottom: 40px;
          ${tablet}{
            margin-bottom: 27px;
          }
        `}> 
          <Link to={brandLink}>{title}</Link>
        </div>
        <p css={css`
          padding-bottom: 36px;
          @media (max-width: 750px) {
            padding-bottom: 24px;
          }
        `}>
          {shortDesc}
        </p>
        <Button 
          css={css`
            margin-bottom: 24px;
            @media (max-width: 750px) {
             margin-bottom: 40px;
            }
          `} 
          to={brandLink}
        >More about the Brand</Button>
        <Button 
          to={catalogueLink}
        >See Products</Button>
      </div>
      <Link 
        to={brandLink}
        css={css`
          grid-row: 1 / 5;
          grid-column: 5/8;
          overflow: hidden;
          @media (max-width: 750px) {
            grid-column: span 12;
            grid-row: 2;
            margin-bottom: 40px;
          }
      `}>
        <div css={css`
            transform: scale(1);
            transition: 0.3s transform;
            height: 100%;

            &:hover{
              /* transform: scale(1.03); */
            }
          `}>
            <Image
              asset={image}
              aspectRatio={1.4}
              css={css`
                /* height: 100%; */
                .gatsby-image-wrapper {
                  height: 100%;
                }
              `}
            />
          </div>
        </Link>
    </div>
  )
}

BrandTile.propTypes = {
  title: PropTypes.string,
  shortDesc: PropTypes.string,
  brandLink: PropTypes.string,
  catalogueLink: PropTypes.string,
  image: PropTypes.object,
}

BrandTile.defaultProps = {
  title: `Lago`,
  shortDesc: `Lago makes furniture for the whole house and design spaces that empathise with the people who live in them. Give shape to your home with LAGO furniture: modular furniture solutions for every room, always in tune with your needs...`,
  brandLink: `/`,
  catalogueLink: `/`,
}

export default BrandTile
