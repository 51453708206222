import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import { breakpoints } from '~styles/global'
import ContactForm from '~components/blocks/ContactForm'
import useSiteSettings from '~utils/useSiteSettings'


const ProductEnquiryForm = ({ className, productName, title, dropdownBackground }) => {
  const { tablet } = breakpoints
  const { productEnquirySuccessMessage } = useSiteSettings()

  return (
    <div
      className={className}
      css={css`
        > h3{
          margin-bottom: 43px;
          ${tablet}{
            margin-bottom: 29px;
          }
        }
        > p{
          margin-bottom: 58px;
          ${tablet}{
            margin-bottom: 46px;
          }
        }
      `}>
      <h3>{title}</h3>
      <h4 css={css`
				margin-bottom: 20px;
			`}>{productName}</h4>
      <ContactForm
        formName="Product Enquiry"
        successMessage={productEnquirySuccessMessage}
        productName={productName}
        dropdownBackground={dropdownBackground}
				conditionalSending={true}
       />
    </div>
  )
}

ProductEnquiryForm.propTypes = {
  title: PropTypes.string,
  productName: PropTypes.string,
  dropdownBackground: PropTypes.string
}

ProductEnquiryForm.defaultProps = {
  title: 'Enquire about this product',
  productName: 'Vele Bed',
  dropdownBackground: '#ffffff'
}

export default ProductEnquiryForm
