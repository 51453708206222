import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import { PlusLine } from '../../Svg';

const ProductAccordionItem = ({ className, title, children, subItem, lastItem, id, startOpen}) => {

  const {mobile} = breakpoints

  const [open, setOpen] = useState(false)

	useEffect(() => {
		startOpen && setOpen(true)
	}, [])

  return (
    <div 
      className={className}
      id={id}
      css={css`
        display: grid;
        grid-template-columns: ${subItem ? `repeat(6, 1fr)` : `repeat(7, 1fr)`}; 
        grid-column-gap: 20px;
        margin-bottom: 35px;
        ${mobile}{
          grid-template-columns: repeat(12, 1fr); 
          grid-column-gap: 10px;
        }
    `}>
      <button
        className={className}
        onClick={() => setOpen(!open)}
        css={css`
          grid-column: ${subItem ? `span 6` : `span 7`};
          display: grid;
          grid-template-columns: ${subItem ? `repeat(6, 1fr)` : `repeat(7, 1fr)`}; 
          grid-column-gap: 20px;
          width: 100%;
          justify-items: start;
          align-items: center;
          margin-bottom: 40px;
          ${mobile}{
            grid-column: span 12;
            margin-bottom: ${subItem ? `39px` : `25px`};
            grid-template-columns: repeat(12, 1fr);
            grid-column-gap: 10px;
          }
        `}
      >
        <h4 css={css`
          grid-column: ${subItem ? `1/6` : `1/7`};
          ${mobile}{
            grid-column: 1/12;
          }
        `}>{title}</h4>
        <div css={css`
          margin-right: 4px;
          position: relative;
          width: 15px;
          height: 15px;
          grid-column: ${subItem ? `6/7` : `7/8`};
          justify-self: ${subItem ? `end` : `start`}; 
          ${mobile}{
            grid-column: 12/13;
            justify-self: end;
          }
        `}>
          <PlusLine css={css`
            position: absolute;
            transform: ${open ? `rotate(0deg)` : `rotate(90deg)`};
            top: 50%;
            left: 0;
            right: 0;
            transition: transform 0.3s;
          `}/>
          <PlusLine css={css`
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
          `}/>
        </div>
      </button>
      <SlideDown 
        className={'my-dropdown-slidedown'} 
        css={css`
          transition: 0.4s ease-in-out;
          grid-column: ${subItem ? `span 6` : `span 7`};
          ${mobile}{
            grid-column: 1/13;
          }
      `}>
        {open ? 
        <div css={css`
          display: grid;
          grid-template-columns: ${subItem ? `repeat(6, 1fr)` : `repeat(7, 1fr)`};
          grid-column-gap: 20px;
          margin-bottom: ${subItem || lastItem ? `0px` : `70px`}; 
          ${mobile}{
            grid-template-columns: repeat(12, 1fr);
            grid-column-gap: 10px;
            margin-bottom: ${subItem || lastItem ? `0px` : `90px`};
          }
        `}
        >
          {children}
        </div>
        : null
        }
      </SlideDown>
    </div>
  )
}

ProductAccordionItem.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
}

ProductAccordionItem.defaultProps = {
  title: 'title',
  children: 'children test',
}

export default ProductAccordionItem
