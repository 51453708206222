import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import Swatch from '../Swatch';
import { CrossThick } from '../../Svg';

const FinishVariantSelector = ({ className, variants, selectedVariant, onUpdate, finishes }) => {

  const {mobile} = breakpoints

  // const [selectedVariant, setSelectedVariant] = useState(null)
  const [hoveredVariant, sethoveredVariant] = useState(null)

  return (
    <div css={css`
      display: grid; 
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 16px;
      row-gap: 16px;
      padding-top: 20px;
      padding-bottom: 32px;
      ${mobile}{
        padding-top: 12px;
        padding-bottom: 4px;
      }
    `}>
      {variants?.map(variant => (
        <button 
          css={css`
            grid-column: span 1;
            position: relative; 
            border: ${ selectedVariant === variant ? `1px solid var(--black)` : `1px solid transparent` };
            border-radius: 50%;
            box-sizing: border-box;
          `} 
          onClick={() => onUpdate(variant)}
          onMouseEnter={() => sethoveredVariant(variant)}
          onMouseLeave={() => sethoveredVariant(null)}
        >
          <Swatch 
            key={variant}
            title={variant}
            image={finishes.find(finish => finish.finish === variant)?.image}
            minimal
            />
        </button>
      ))}
      <div css={css`
        display: flex;
        justify-content: space-between;
        grid-column: span 6;
        margin-top: 17px;
        opacity: ${selectedVariant || hoveredVariant ? `1` : `0`};
        transition: 0.3s opacity;
        ${mobile}{
          opacity: 0;
        }
      `}>
        <span className={`h6`} >{hoveredVariant ? hoveredVariant : <br/>}</span>
        {/* <button 
          onClick={() => setSelectedVariant(null)}
          className={`h6`} 
          css={css`
            color: var(--grey);
            opacity: ${selectedVariant ? `1` : `0`};
            transition: 0.3s opacity;
        `}>
          <CrossThick css={css`
            width: 9px;
            height: 9px;
            margin-right: 8px;
          `}/> 
          Clear
        </button> */}
      </div>
    </div>
  ) 
}

FinishVariantSelector.propTypes = {
  subVariants: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.object,
  }))
}

FinishVariantSelector.defaultProps = {
  variants: [
    {
      key: `1`,
      title: `Finish Varaint 1`,
    },
    {
      key: `2`,
      title: `Finish Varaint 2`,
    },
    {
      key: `3`,
      title: `Finish Varaint 3`,
    },
    {
      key: `4`,
      title: `Finish Varaint 4`,
    },
    {
      key: `5`,
      title: `Finish Varaint 5`,
    },
    {
      key: `6`,
      title: `Finish Varaint 6`,
    }
  ]
}

export default FinishVariantSelector
