import React, { useState, useLayoutEffect } from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import { ChevronDown, Plus } from '../../Svg';
import FinishVariantSelector from  '~components/blocks/FinishVariantSelector'

const VariantAccordionItem = ({ className, title, children, selected, subItem, values, onChange, validValues, initialValue, allFinishes}) => {

  const { mobile } = breakpoints

  const [open, setOpen] = useState(false)
  const [selectedValue, setSelectedValue] = useState(false)

  const finishesWithSwatches = allFinishes?.filter(finish => finish.relatedOption && finish.image)

  const formattedFinishes = finishesWithSwatches?.map(finish => ({
    option: finish.relatedOption.split(':')[0],
    finish: finish.relatedOption.split(':')[1],
    image: finish.image
  }))

  const availableFinishes = formattedFinishes?.filter(finish => finish.option === title)

  const displayAsSwatches = availableFinishes?.length > 0

  const updateSelectedValue = val => {
    if(onChange){
      onChange(val)
    }
    setOpen(false)
    setSelectedValue(val)
  }

  useLayoutEffect(() => {
    if(initialValue){
      setSelectedValue(initialValue)
    }
  }, [initialValue])

  return (
    <div 
      className={className}
      css={css`
        border-bottom: ${!subItem ? `1px solid var(--grey)` : `none`}; 
    `}>
      <button
        onClick={() => values.length > 1 ? setOpen(!open) : null}
        css={css`
          display: flex;
          justify-content: ${!subItem ? `space-between` : `start`};
          align-items: center;
          width: 100%;
          padding: ${!subItem ? `8px 0` : `15px 0`};
					cursor: ${values.length > 1 ? 'pointer' : 'default'};
          ${mobile}{
            padding: 7px 0;
            justify-content: space-between;
          }
        `}
      >
        <h6>{title}</h6>
        { !subItem ? 
          <div css={css`display: flex; align-content: center;`}>
            <h6 css={css`
              opacity: ${open ? `0` : `1`};
              transition: 0.3s opacity;
              display: inline-block;
            `}>
              {selectedValue !== " " ? selectedValue : `Select`}
            </h6>
						{values.length > 1 &&
							<ChevronDown css={css`
								width: 12px;
								height: 13.75px;
								transform: ${open ? `rotate(180deg)` : `rotate(0deg)` };
								transition: 0.3s transform;
								margin-left: 14px;
								${mobile}{
									margin-left: 17px;
								}
							`}/>
						}
            
          </div>
          :
          <Plus css={css`
            width: 10px;
            height: 10px;
            margin-left: 6px;
            transform: ${open ? `rotate(45deg)` : `rotate(0deg)` };
            transition: 0.3s ease-in-out transform;
            ${mobile}{
              width: 13px;
              height: 13px;
              margin-left: 6px;
            }
          `}/>
        }
      </button>
      <SlideDown 
        className={'my-dropdown-slidedown'} 
        css={css`
          transition: 0.4s ease-in-out;
      `}>
        {open && !displayAsSwatches && 
        <div css={css`
            padding-top: 25px;
            padding-bottom: 9px;
            ${mobile}{
              padding-bottom: 5px;
            }
          `}>
            {values.map(value => (
              <div
                key={value + title} 
                css={css`
                  margin-bottom: 21px;
                  ${mobile}{
                    margin-bottom: 20px;
                  }
              `}>
                <button 
                  onClick={() => updateSelectedValue(value)}
                  className={`h6`}
                  css={css`
                    /* opacity: ${validValues && validValues.includes(value) ? 1 : 0.5}; */
                    /* pointer-events: ${validValues && validValues.includes(value) ? 'all' : 'none'}; */
                  `}
                  >
                    {value}
                </button>
              </div>
            ))}
						{/* Remove none for now */}
            {/* <div
							key={title + '_none'} 
							css={css`
								margin-bottom: 21px;
								${mobile}{
									margin-bottom: 20px;
								}
						`}>
							<button 
								onClick={() => updateSelectedValue(" ")}
								className={`h6`}
								css={css`
									opacity: 0.5;
								`}
								>
									None
							</button>
						</div> */}
          </div>
        }
      </SlideDown>
      <SlideDown>
      {open && displayAsSwatches &&
          <FinishVariantSelector variants={values} selectedValues={initialValue} onUpdate={value => updateSelectedValue(value)} finishes={availableFinishes}/>
      }
      </SlideDown>

    </div>
  )
}

VariantAccordionItem.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  selected: PropTypes.string,
}

VariantAccordionItem.defaultProps = {
  title: 'title',
  children: 'children test',
  // selected: 'Selected Item',
}

export default VariantAccordionItem
